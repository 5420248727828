import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ReportsWrapper from '../../components/ReportsWrapper'
import { Spacer, ReportArchive, CircularProgress, CardCollapse } from '../../components/library'
import parseCACTData from '../../utils/parseCACTData'
import { ImpactContext } from '../../components/Layout'
import { UserContext } from '@parallelpublicworks/entitysync'
import CACTScale from '../../components/CACTScale'

const useStyles = makeStyles(theme => ({
  actions: {
    display: 'flex'
  }
}))

function CACT() {
  const classes = useStyles()
  const { userData, user } = useContext(ImpactContext)
  const userContext = useContext(UserContext)

  const [CACTData, setCACTData] = useState(null)

  useEffect(() => {
    if (!CACTData && userData) {
      setCACTData(parseCACTData(userData, 'report'))
    }
  }, [userData])

  if (CACTData === null) return <CircularProgress />

  return (
    <ReportsWrapper currentTab={2}>
      <>
        <Spacer />
        <ReportArchive data={CACTData} title="C-ACT REPORTS" searchPlaceholder="Search by score or level..." />
        <Spacer />
        <CardCollapse title="Legend">
          <CACTScale />
        </CardCollapse>
      </>
    </ReportsWrapper>
  )
}

export default CACT
